.nav-bar-wrapper {
    background-color: var(--primary-color);
    height: var(--nav-bar-height);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    color: var(--neutral-color-01);

    .nav-bar__option-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        cursor: pointer;


        img {
            filter: brightness(0) invert(0.8);
            height: 20px;
            object-fit: contain;
        }

        h5 {
            margin: 0;
            font-weight: 300;
        }

        &.active {
            img {
                filter: brightness(0) invert(1);
            }

            h5 {
                font-weight: 500;
            }
        }
    }

    .nav-bar__option-wrapper-svg {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      cursor: pointer;

      h5 {
        margin: 0;
        font-weight: 300;
      }

      &-icon {
        height: 25px;
        fill: var(--primary-color);
        stroke: var(--neutral-color-01);
      }

      &.active {
        .nav-bar__option-wrapper-svg-icon {
          fill: var(--neutral-color-01);
          stroke: var(--neutral-color-01);
        }

        h5 {
          font-weight: 500;
        }
      }
    }
}

.side-nav__inner-wrapper {
  color: #76747B !important;
}

.side-nav__logout-icon img {
  filter: initial !important;
}