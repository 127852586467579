.table {
    &-external-wrapper {
        margin-bottom: 12px;
        width: 100%;
        overflow-y: hidden;
        min-height: 250px;
        padding-right: 6px;

        &--no-overflow-y {
            min-height: initial;
            overflow-y: initial;
        }

        @include until($tablet) {
            overflow: auto; /* Mantiene el scroll funcional */
            scrollbar-width: none; /* Oculta en Firefox */
            -ms-overflow-style: none; /* Oculta en IE y Edge */
            margin-top: 20px;
            ::-webkit-scrollbar {
                display: none; /* Oculta en Chrome, Safari y Opera */
            }
        }
    }

    &-header {
        width: 100%;
        display: flex;
        align-items: center;

        h2 {
            margin-right: 24px;
            margin-bottom: 0;
        }
    }

    &-inner-box {
        min-width: 900px;
        padding-right: 10px;
        min-height: 200px;

        &--large {
            min-width: 900px;
        }
    }

    &-box {
        width: 100%;
        max-width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
    }

    &-options {
        display: flex;
        gap: 64px;
        font-style: italic;
        color: var(--neutral-color-03);

        h3 {
            cursor: pointer;
            margin-bottom: 0;
            margin-top: 12px;

            &.is-selected {
                color: var(--black-color);
                text-decoration: underline;
                font-weight: 600;
            }
        }
    }
}


.without-search{
    min-height: 64px !important;
}

.quotation-table {
    .prt-table-top__actions--icon {
        img {
            filter: invert(77%) sepia(51%) saturate(472%) hue-rotate(352deg) brightness(1000%) contrast(95%);
            width: 20px !important;
            height: 20px !important;
        }
        span {
            color: white !important;
        }
    }

    @include from($tablet) {
        .prt-table-top__actions--icon {
            padding: 25px 22px 25px 22px;
        }
    }
}

:not(.product-table) {
  .prt-table-top__actions--icon {
    padding: 20px 8px 20px 8px;
    border-radius: 10px;
    background-color: #f08300;
    span {
        color: white !important;
    }

    img {
      width: 20px !important;
      height: 20px !important;
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(1000%) contrast(100%);
    }
  }

  @include from($tablet) {
    .prt-table-top__actions--icon {
        padding: 25px 22px 25px 22px;
    }
  }
}

.product-table {
    .prt-table-top__actions--icon:last-child {
        img {
            display: none;
        }
    }
    .prt-table-top__actions--icon:first-child {
        padding:  20px 8px 20px 8px;
        border: 1px;
        border-style: solid;
        border-color: #284193;
        border-radius: 10px;
        background-color: white;
        img {
            width: 20px !important;
            height: 20px !important;
            filter: inherit;
        }
        span {
            color: #284193 !important;
        }
    }

    @include from($tablet) {
        .prt-table-top__actions--icon {
            padding: 25px 22px 25px 22px;
        }
        .prt-table-top__actions--icon:first-child {
            padding: 25px 22px 25px 22px;
        }
    }
}

@media (min-width: 1024px) {
    .datatable-body-row, .datatable-row-center, .datatable-scroll {
        max-width: 100% !important;
    }
}

:not(.table-with-tabs) {
    .datatable-body {
        height: calc(100vh - 345px) !important;
        overflow-y: auto;
        @include until($tablet){
            height: calc(100vh - 385px) !important;
        }
    }
}

.table-with-tabs{
    .datatable-body{
        height: calc(100vh - 410px) !important;
        overflow-y: auto;
        @include until($tablet){
            height: calc(100vh - 500px) !important;
        }
    }
}

.table-container:has(.prt-table-top__actions--search-wrapper) .datatable-body {
    min-height: 100% !important;
}

.ngx-datatable .datatable-footer{
    height: 52px;
}
