@import "../mixins/mixins";
.tab-menu-wrapper {

    width: 100%;
    min-height: 30px;
    border-bottom: 1px solid var(--neutral-color-03);
    display: flex;
    align-items: flex-end;
    gap: 48px;
    overflow-x: auto;

    .tab-option {
        color: var(--neutral-color-03);
        cursor: pointer;

        &.selected {
            font-weight: 600;
            color: var(--black-color);
            border-bottom: 2px solid var(--black-color);
        }
    }

    @include until($tablet) {
        width: 100%;
        max-width: 100%;
        margin-bottom: 0px;

        overflow: auto; /* Mantiene el scroll funcional */
        scrollbar-width: none; /* Oculta en Firefox */
        -ms-overflow-style: none; /* Oculta en IE y Edge */
        ::-webkit-scrollbar {
            display: none; /* Oculta en Chrome, Safari y Opera */
        }
    }
}