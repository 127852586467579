@import "../mixins/mixins";

.create {
    &__content-wrapper {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: flex-start;
        width: 100%;
        overflow: hidden;
    }

    &__img-wrapper {
        position: relative;
        margin-bottom: 24px;
        width: 144px;
        height: 144px;
        margin: 0 auto 24px auto;

        .img-box {
            width: 107px;
            height: 107px;
            border-radius: 100%;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .add-icon {
            position: absolute;
            right: -18px;
            top: 0;
            width: 2rem;
            height: 2rem;

            img {
                height: 100%;
                object-fit: contain;
            }

            input {
                cursor: pointer;
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }
        }
    }

    &__header-wrapper {
        height: 64px;
        /* z-index: 100; */
        background-color: var(--neutral-color-01);
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        flex-wrap: wrap;

        h1,
        h4 {
            margin: 0;
        }

        &--column {
            height: 72px;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: flex-start;
        }
    }

    &__body-wrapper {
        height: 100%;
        width: 100%;
        max-width: 767px;
        margin: 0 auto;
        overflow-y: hidden;
        overflow-x: hidden;
        display: flex;
        justify-content: flex-start;
        padding-top: 18px;
        flex-direction: column;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
            width: 0;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.5);
        }

        &::-webkit-scrollbar-track {
            background-color: rgba(0, 0, 0, 0.1);
        }

        @include from($tablet) {
            justify-content: center;
            padding-top: 0;
        }

        &--no-center {
            @include from($tablet) {
                justify-content: flex-start;
                padding-top: 18px;
            }
        }

        h1 {
            text-transform: capitalize;
        }

        &--block {
            justify-content: flex-start;
            max-width: 100%;
        }

        &--with-margin-top {
            margin-top: 36px;
        }

        .horizontal-list__wrapper {
            display: flex;
            width: 100%;
            align-items: center;
            margin-bottom: 24px;
            padding: 24px 12px;
            overflow-x: auto;
            min-height: 180px;

            .horizontal-list__inner-box {
                display: flex;
                gap: 24px;
                align-items: center;
                flex: 1;
                justify-content: flex-start;

                .horizontal-list__option-box {
                    display: flex;
                    flex-direction: column;
                    width: 194px;
                    height: 127px;
                    gap: 16px;
                    box-shadow: 0px 4px 10px var(--neutral-color-02);
                    border-radius: 10px;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    padding: 14px 0;

                    &:hover {
                        background-color: var(--primary-color-hover-50);
                    }

                    img {
                        max-height: 96px;
                        height: 50%;
                        max-width: 128px;
                        object-fit: contain;
                    }

                    .horizontal-list__option-label {
                        font-size: var(--header-h6-font-size);
                        font-weight: var(--header-h6-font-weight);
                        line-height: var(--header-h6-line-height);
                    }
                }
            }

            &::-webkit-scrollbar {
                width: 0px;
                height: 0px;
            }
        }
    }

    &__button-box {
        display: flex;
        align-items: center;
        gap: 6px;
        position: relative;

        input {
            opacity: 0;
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }

        span {
            font-size: 1.5rem;
            font-weight: 400;
        }
    }

    &__button-icon {
        height: 1.5rem;
        object-fit: contain;
    }

    &__footer-wrapper {
        height: 0px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap-reverse;
        align-content: center;

        column-gap: 16px;
        row-gap: 12px;
        margin-bottom: 14px;
        margin-top: 18px;
        background-color: var(--neutral-color-01);
        z-index: 100;
        flex-direction: none;

        @include from($tablet) {
            height: 64px;
            flex-direction: row;
            align-items: flex-end;
            flex-direction: column-reverse;
            column-gap: 64px;
        }

        @include until($tablet) {
            height: auto;
            flex-direction: row-reverse;
            align-items: center;
            column-gap: 64px;
            align-content: space-between;
            margin-bottom: 0px;
            position: fixed;
            bottom: 0;
            background-color: white;
            padding: 18px 0px;
        }
    }
}

.create__button-icon {
    height: 1rem;
}

@media (max-width: 380px) {
    .create__footer-wrapper {
        height: 119px;
    }
}
