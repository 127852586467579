
$mobile: 376px;
$tablet: 768px;
$desktop: 1024px;
$xl-desktop: 1440px;
$xxl-desktop: 1920px;

$breakPoints: $xxl-desktop, $xl-desktop, $desktop, $tablet, $mobile;

@mixin from($px) {
    @media (min-width: $px) {
      @content;
    }
  }
  
  @mixin until($px) {
    @media (max-width: $px) {
      @content;
    }
  }