.form, .create__form {
    &-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 32px;
        overflow: auto;
    }

    &-box {
        width: 100%;
    }
}

.prt__button--outline, .prt__button--solid{
    padding: 0px !important;
    @include from($tablet) {
        padding:var(--button-padding) !important;
    }
}
