/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "~ngx-toastr/toastr";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "./styles/mixins/mixins";
@import "./styles/table/table";
@import "./styles/form/form";
@import "./styles/layout/layout";
@import "./styles/modal/modal";
@import "./styles/layout/create-layout";
@import "./styles/tabs/tab-menu";
@import "./styles/layout/resume-layout";
@import "./styles/layout/nav-bar";

* {
    box-sizing: border-box;
}
// Reset
body {
    margin: 0;
    font-family: "Poppins", sans-serif;
    touch-action: none;
}

main {
    //Variables
    --form-column-gap: 20px;
    --primary-color: #0070B3;
    --secondary-color: #F08300;
    --primary-color-50: #0384a61a;
    --side-nav-icon-height: 24px;
    --side-nav-icon-width: 24px;
    --button-font-weight: 400;
    --button-font-size: 1.25rem;
    --button-solid-background: #f08300;
    --button-outline-color: #f08300;
    --button-outline-border-color: #f08300;
    --input-border-color: #95939a;
    --bg-primary-color: rgba(255, 245, 244, 0.338);
    --primary-color-hover-50: #C3C3C366;
    --input-on-focus-shadow: 0px 0px 6px #008DD4;
    --input-on-focus-outline: #008DD4;
    --success-color: #03aa14;
    --danger-color: #f00000;
    --nav-bar-height: 72px;
    --neutral-color-01: #fff;
    --table-actions-label-color: var(--secondary-color);

    /* Headings */
    --header-h1-font-size: 1.75rem;
    --header-h1-font-weight: 500;
    --header-h1-line-height: 2.6rem;

    --header-h2-font-size: 1.25rem;
    --header-h2-font-weight: 500;
    --header-h2-line-height: 1.8rem;

    --header-h3-font-size: 1.25rem;
    --header-h3-font-weight: 500;
    --header-h3-line-height: 1.7rem;

    --header-h4-font-size: 1rem;
    --header-h4-font-weight: 500;
    --header-h4-line-height: 1.5rem;

    --header-h5-font-size: 0.87rem;
    --header-h5-font-weight: 400;
    --header-h5-line-height: 1.3rem;

    --header-h6-font-size: 0.75rem;
    --header-h6-font-weight: 400;
    --header-h6-line-height: 1.1rem;

    /* Phone Headings */
    --phone-header-h1-font-size: 1.25rem;
    --phone-header-h1-font-weight: 500;
    --phone-header-h1-line-height: 1.8rem;

    --phone-header-h2-font-size: 1rem;
    --phone-header-h2-font-weight: 500;
    --phone-header-h2-line-height: 1.5rem;

    --phone-header-h3-font-size: 0.87rem;
    --phone-header-h3-font-weight: 500;
    --phone-header-h3-line-height: 1.5rem;

    --phone-header-h4-font-size: 0.75rem;
    --phone-header-h4-font-weight: 500;
    --phone-header-h4-line-height: 1.1rem;

    --phone-header-h5-font-size: 0.65rem;
    --phone-header-h5-font-weight: 400;
    --phone-header-h5-line-height: 0.9rem;

    --phone-header-h6-font-size: 0.5rem;
    --phone-header-h6-font-weight: 600;
    --phone-header-h6-line-height: 0.75rem;

    /* Paragraphs */

    --paragraph-p2-font-size: 0.9rem;
    --paragraph-p2-font-weight: 300;
    --paragraph-p2-line-height: 1.3rem;

    --paragraph-p3-font-size: 0.75rem;
    --paragraph-p3-font-weight: 300;
    --paragraph-p3-line-height: 1rem;

    --paragraph-p4-font-size: 0.75rem;
    --paragraph-p4-font-weight: 300;
    --paragraph-p4-line-height: 1rem;

    --phone-paragraph-p4-font-size: 1rem;
    --phone-paragraph-p4-font-weight: 400;
    --phone-paragraph-p4-line-height: 1.2rem;

    --paragraph-p6-font-size: 1.1rem;
    --paragraph-p6-font-weight: 500;
    --paragraph-p6-line-height: 1.7rem;

    --phone-paragraph-p6-font-size: 0.87rem;
    --phone-paragraph-p6-font-weight: 500;
    --phone-paragraph-p6-line-height: 1.1rem;

    /* Side nav */

    --side-nav-bg-color: var(--neutral-color-01);
    --side-nav-color: #76747B;
    --side-nav-selected-bg-color: var(--neutral-color-01);
    --side-nav-selected-color: var(--primary-color);
    --side-nav-sublist-bg-color: var(--neutral-color-01);
    --side-nav-sublist-color: var(--primary-color);
    --side-nav-sublist-selected-color: var(--secondary-color);
    --side-nav-icon-fill: var(--neutral-color-01);
    --side-nav-icon-fill-hover: var(--primary-color);
    --side-nav-icon-stroke: #76747B;
    --side-nav-icon-stroke-hover: var(--primary-color);
    --side-nav-width: 96px;
    --side-nav-bottom-margin: 25px;

    // buttons
    --button-outline-color: #284193;
    --button-outline-border-color: #284193;

    --button-primary-color: #284193;

    /* Input */
    --input-font-size:0.8rem;

    &.main--full {
        height: 100vh;
        padding: 20px;
    }

    button {
        font-family: "Poppins", sans-serif;
    }

    //scroll
    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background: var(--neutral-color-03);
        border-radius: 10px;
    }
}

.prt-btn__outline--primary, .prt-btn__solid--primary, .prt-btn__solid--secondary {
    height: 58px;
    display: flex !important;
    align-items: center;
}

.prt-btn__solid--primary {
    background-color: var(--button-primary-color) !important;
}

.prt-btn__outline--primary{
    color: var(--button-primary-color);
    border: 1px solid var(--button-primary-color) !important;
}

.prt-btn__outline--primary:hover{
    background-color: var(--button-primary-color) !important;
}

/*aqui*/
.datatable-row-even {
  background: var(--neutral-color-01) !important;
}
.datatable-row-odd {
  background: var(--neutral-color-01) !important;
}

.datatable-body-row:hover {
  background: var(--primary-color-hover-50) !important;
}

.input__with-image {
    max-width: 100% !important;
}

// change input placeholder color
::placeholder {
    color: var(--neutral-color-03) !important;
}

.side-nav__footer-icon--svg {
  stroke: none !important;
}

.prt-form-field-xl{
    max-width: none !important;
}
