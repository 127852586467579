.modal {
  &-wrapper {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;

    &--payment {
      align-items: center;
      margin-bottom: 0;
    }
  }

  &-buttons-wrapper {
    align-items: flex-end;
    display: flex;
    gap: 16px;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap-reverse;
    margin-top: 32px;

    @include from($tablet) {
      gap: 64px;
    }

    &--absolute {
      margin-top: 0;
      bottom: 24px;
      left: 0;
      position: absolute;
    }
  }

  &-box {
    &.modal-normal {
      min-height: 300px !important;

      @include until($tablet) {
        min-height: 100% !important;
      }
    }

    @include until($tablet) {
      width: 100% !important;
      min-height: 100% !important;
    }
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  white-space: normal !important;
}

.wpwl-container {
  position: relative;
  padding: 10px;
}

button.prt__button--solid {
  background-color: #f08300 !important;
}

button.prt__button--outline {
  color: #284193 !important;
  border: 1px solid #284193 !important;
}

.text-primary {
  color: #0070b3 !important;
}

.close-btn a {
  position: relative;
  color: transparent;
}

.close-btn a::after {
  content: "X";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--secondary-color);
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  text-underline-position: from-font;
}